import React from "react"
import { Helmet } from "react-helmet"

const SEO = (data) =>
{   
    const seo = data.seo;
    const sitename = seo.env.site === 'dumpster' ? 'Hometown Dumpster Rental' : 'Hometown Demolition';
    const state_name = seo.place !== undefined ? seo.place.state.name : (seo.state !== undefined ? seo.state.name : '');
    const place_full = seo.place !== undefined ? seo.place.name : '';
    const place_small = seo.place !== undefined ? seo.place.name.split(',')[0] : '';
    const service = seo.seo_desc !== undefined ? seo.seo_desc[0].name : '';   

    const getTime = () =>
    {
        const addzero = (chr) => { return chr < 10 ? '0'+chr : chr; }
        const d = new Date(),
        now = [ addzero( (d.getMonth()+1) ),
                addzero( d.getDate() ),
                d.getFullYear()].join('/')+
                ' ' +
                [ addzero( d.getHours() ),
                addzero( d.getMinutes() ),
                addzero( d.getSeconds() )].join(':'); 
                    
        return now;
    }  
    
    const getTitle = () =>
    {
        let title;       
        
        switch(seo.page)
        {
            case 'blogs': title = seo.env.site === 'dumpster' ? 'Waste Removal Tips & Advice' : 'Demolition Tips & Advice'; break;            
            case 'guides': title = 'Pro Guides'; break;
            case 'blog': case 'guide': title = seo.blog.title; break;
            case 'tags': title = seo.tag ? seo.tag : 'Tips & Articles'; break
            case 'ppp': title = `${service} in ${place_full}`; break;            
            case 'state': title = `${seo.seo_desc[0].kg_seo_label} in ${state_name}`; break;                
            case 'allstate': title = `All ${seo.seo_desc[0].kg_seo_label} in ${state_name}`; break; 
            case 'kpp': 
                switch(seo.code)
                {
                    case 'dumpster':
                        title = `Hometown Dumpster Rental - Best Prices in ${place_full}`
                        break
                    
                    default:
                        title = `${seo.seo_desc[0].kg_seo_label} in ${place_full}`
                        break
                }   
                break
            case 'basic': title = seo.title; break;
            case 'profile': title = seo.title; break;
            case 'nearme': title = seo.title; break;

            default: title = `${service}`; break;
        }

        return title;
    }

    const getDescription = () =>
    {
        if(seo.description) return seo.description
        
        let description = seo.seo_desc !== undefined ? seo.seo_desc[0] : '';    
        switch(seo.page)
        {
            case 'kpp': description = description.kg_kpp_seo_desc; break;
            case 'ppp': description = description.kg_panel_page_seo_desc; break;
            case 'state': case 'allstate': description = description.kg_state_seo_desc; break;
            case 'blog': case 'guide': description = seo.blog.teaser !== undefined ? seo.blog.teaser.replace(/&nbsp;/gi, ' ').replace(/<p>/gi, '').replace(/<[^>]+>/g, '').replace(/(\r\n|\n|\r)/gm, "") : ''; break;
            case 'tags': description = null; break
            case 'blogs': case 'guides': description = seo.env.site === 'dumpster' ? 'Waste Removal Tips & Advice' : 'Demolition Tips & Advice'; break;
            case 'basic': description = seo.description; break;
            case '404': description = '404 Page Not Found'; break;
            case 'nearme': description = seo.description; break;
            default: description = description.kg_homepage_seo_desc; break;
        } 

        if(description)
        {
            description = description.replace(/STATE_NAME/g, state_name);
            description = description.replace(/PLACE_NAME/g, (seo.code === 'dumpster' ? place_small : place_full) );   
        }   

        return description;
    }

    const getImage = () =>
    {
        let path = '/seo/'+(seo.env.site === 'dumpster' ? 'hometown-dumpster-rental-logo.png' : 'hometown-demolition-contractors-logo.png');

        switch(seo.page)
        {
            case 'blog': case 'guide': path = seo.blog.image !== null ? seo.env.domain+seo.blog.image.path : path; break;
            case 'profile':
                if(!seo.image) path = path
                if(seo.image !== false) path = seo.image
                break
            case 'nearme': path = seo.image; break;
            default:break;
        }

        return seo.env.domain+path;
    }

    let datas_title = ''

    switch(seo.code)
    {
        case 'dumpster':

            switch(seo.page)
            {
                case 'kpp': case 'tags':
                    datas_title = getTitle()
                    break;

                default:
                    datas_title = `${getTitle()} | ${sitename}`
                    break;
            }

            break;
        
        case 'junk-removal':
            datas_title = `${getTitle()} | Hometown`
            break;

        default:
            
            switch(seo.page)
            {
                case 'tags':
                    datas_title = getTitle()
                    break;

                default:
                    datas_title = `${getTitle()} | ${sitename}`
                    break;
            }
            break;
    }
   
    const datas =
	{
		title: datas_title,
		title_template: datas_title,
		description: getDescription(),
		published_time: getTime(),
		modified_time: getTime(),
		og:
		{
			site_name: sitename,
			type: 'article',
			url: (`${seo.env.domain}/${seo.url}`).replace(/\/\/+/g,'/'),
			title: getTitle(),
			description: getDescription(),
			updated_time: getTime(),
			image: getImage()
		}
	}

    const favicon = seo.env.domain+'/favicon.png?v=1.0.1';

    return (
		<Helmet title={datas.title} titleTemplate={datas.title_template}>
			<link rel="profile" href="https://www.w3.org/1999/xhtml/vocab" />
            <link rel="icon" href={favicon} type="image/png" />  
            {seo.title && <meta name="title" content={datas.og.title} /> }
            {datas.description !== null && <meta name="description" content={datas.og.description} /> }
			<meta property="og:url" content={datas.og.url} />
			<meta property="og:title" content={datas.og.title} />
			{datas.description !== null && <meta property="og:description" content={datas.og.description} /> } 
			<meta property="og:image" content={datas.og.image} />
			<meta property="og:type" content={datas.og.type} />      
		</Helmet>
    )
}

export default SEO