import React from "react";
import { Ondeff } from '@hometown/react-hometown-lib'


//import loadable from '@loadable/component'
//const  Ondeff  = loadable.lib(() => import('@hometown/react-hometown-lib'))


const OnDeffImplementation = (props) => {
    const formCode = props.formCode;
    const initialData = props.initialData;
    const setLoading = props.loadingFunction;
    const setProName = props.setProName

    return (
        <>
            <Ondeff formCode={formCode}
                showSuccessfulMessageAfterSubmit={true}
                apolloUrl={`${process.env.GATSBY_BOOKABLE_SEARCH_API}`}
                showStepper={false}
                formTitle={null}
                onSubmitSucessfuly={null}
                handleAfterSubmit={() => {setLoading(false); setProName && setProName('')}}
                handleAfterErrorOnSubmit={() => {setLoading(false); setProName && setProName('') }}
                onSubmitFunction={() => setLoading(true)}
                initialData={initialData}>
            </Ondeff></>
    )
}

export default OnDeffImplementation;