import React from "react";

export default class SocialsDumpsters extends React.Component {

    render() 
    {   
        return (        
            <ul className="socials">
                <li>
                    <img className="logo-footer-img" src="/images/logo-dumpster.webp" alt="Hometown logo"></img>
                </li>
                <li className="front-li social">
                    <a className="front-link" role="button" aria-label="facebbok" rel="noreferrer" href="https://www.facebook.com/HometownDumpsterRental/" target="_blank"><i className="fab fa-facebook-square" ></i></a>
                    <a className="front-link" role="button" aria-label="twitter" rel="noreferrer" href="https://twitter.com/hometowndumpstr" target="_blank"><i className="fab fa-twitter-square" ></i></a>
                    <a className="front-link" role="button" aria-label="pinterest" rel="noreferrer" href="https://www.pinterest.com/hometowndiytips/" target="_blank"><i className="fab fa-pinterest-square" ></i></a>          
                    <a className="front-link" role="button" aria-label="youtube" rel="noreferrer" href="https://www.youtube.com/c/HometownDumpsterRental" target="_blank"><i className="fab fa-youtube-square" ></i></a>
                </li>
            </ul>
        );
    }
}